import React, { ForwardedRef, forwardRef, ReactElement } from 'react'
import cn from 'classnames'

import styles from './Tariffs.module.scss'
import { Tariff } from '../Tariff/Tariff'
import { LandingButton } from 'src/shared/ui/LandingButton'

interface TariffsPropsType {
  className: string
}

const TariffsWithRef = (
  { className }: TariffsPropsType,
  forwardedRef: ForwardedRef<HTMLDivElement>
): ReactElement<TariffsPropsType> => {
  return (
    <div className={cn(styles.Tariffs, className)} ref={forwardedRef}>
      <div className={styles.TitleRow}>
        <h2 className={styles.Title}>
          Наши тарифы
        </h2>
        <LandingButton className={styles.LandingButton}>
          Зарегестрироваться
        </LandingButton>
      </div>
      <div className={styles.Container}>
        <Tariff
          title="Базовый"
          integrations="1 – 25 интеграций"
          items={[
            "Reels & stories у блогера",
            "Отзыв с фото",
            "Выкуп по ключевой фразе"
          ]}
          price="600₽"
        />
        <Tariff
          title="Стандартный"
          integrations="26 – 60 интеграций"
          items={[
            "Reels & stories у блогера",
            "Отзыв с фото",
            "Выкуп по ключевой фразе"
          ]}
          price="500₽"
        />
        <Tariff
          title="Максимум"
          integrations="61 – 100 интеграций"
          items={[
            "Reels & stories у блогера",
            "Отзыв с фото",
            "Выкуп по ключевой фразе"
          ]}
          price="400₽"
        />
        <Tariff
          className={styles.TariffPremium}
          title="Все включено"
          integrations="50 интеграций"
          items={[
            "Супер функция – самостоятельный выбор блогера",
            "Reels & stories у блогера",
            "Отзыв с фото",
            "Выкуп по ключевой фразе"
          ]}
          price="600₽"
          isPremium
        />
      </div>
    </div>
  )
}

export const Tariffs = forwardRef(TariffsWithRef)
