import { useMutation } from '@tanstack/react-query'
import { AdminAPI, CommonMessageResponse, HttpResponse, SignUpDto } from 'src/shared/api'

export const useRegistrateMutation = () => {
  return useMutation<
    HttpResponse<CommonMessageResponse, CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, CommonMessageResponse>,
    SignUpDto
  >({
    mutationFn: AdminAPI.api.authControllerSignUpDto,
  })
}
