import React, { FC, useState } from 'react'
import cn from 'classnames'
import { ReactComponent as ArrowDown } from 'src/shared/assets/faq_arrow_down.svg'
import { ReactComponent as ArrowUp } from 'src/shared/assets/faq_arrow_up.svg'

import styles from './FAQ.module.scss'

interface FAQPropsType {
  className: string
}

const QUESTIONS = [
  {
    title: 'Какой результат у меня получится?',
    answer: 'Ваш товар и бренд порекомендуют разные микроблогеры у себя в блоге со средним количеством просмотров (охватов) от 1000 - 10 000 в reels&stories. А самое главное выкупят ваш товар по заданному ключевому слову, оставят на МП положительный, развернутый отзыв с фото и видео вашего товара. Деньги платить блогерам не нужно. Каждому блогеру вы предоставите рекламируемый товар, который и является вознаграждением за его труд.',
  },
  {
    title: 'Почему сейчас выгоднее работать с микроблогерами?',
    answer:
      <span className='preLineText'>{`1. Доверие: Микроблогеры имеют более лояльную аудиторию. Подписчики чувствуют личное отношение и склонны больше доверять мнению блогера.

2. Большая вовлеченность: Маленькие блогеры чаще общаются с аудиторией, отвечают на комментарии, проводят опросы и конкурсы. Это невероятно важно для привлечения внимания к бренду.

3. Экономия бюджета: Сотрудничество с микроблогерами обходится дешевле, чем с крупными инфлюенсерами, при этом приносит те же результаты или даже более высокую конверсию, благодаря более персональному подходу.`}</span>,
  },
  {
    title: 'Сколько стоит одна интеграция с блогером?',
    answer: <span className='preLineText'>{`Стоимость одной интеграции примерно 400 рублей + ваш товар. Разберем кейс с вашим товаром стоимостью 800 рублей, при покупке 50 интеграций на нашем сайте: 20 000 рублей (комиссия сервиса) + сумма 50 ваших товаров для блогеров.
- 50 выкупов блогерами с разный регионов по ключевому запросу с забором с ПВЗ
- положительный и развернутый отзыв с фото от каждого блогера
- И самое главное - это 50 рекламных интеграций от проверенных блогеров с реальными охватами от 1000 просмотров и выше в сторис и рилс, с UTM-меткой на ваш товар, а также отметка артикула вашего товара и отметка вашего бренда в инст`}</span>,
  },
  {
    title: 'Как я могу оплатить за товар блогеру?',
    answer: 'После того, как блогер загрузит на сайт для вас подтверждение: скриншот «выкупа по ключевому слову», «фильтра по цене», «доставки в пвз» - вам автоматически придут реквизиты блогеров для перевода ему по номеру карты или по номеру телефона.',
  },
  {
    title: 'Могу ли я сам выбирать блогеров?',
    answer: 'Да, помимо автоматического набора блогера на задания, у нас предусмотрен также ручной сбор блогеров, где вы сможете самостоятельно на свое задание принимать или отклонять блогера',
  },
  {
    title: 'В течении какого времени выполнится мое задание, и как я смогу отслеживать действия блогера?',
    answer: <span className='preLineText'>{`Рассмотрим кейс на 50 выкупов:
Вы размещаете нужное вам количество выкупов, если не хотите собирать сразу 50 выкупов, то можно разбить на любое удобное количество для вас по выкупам и дням, например, можно в течении 5 дней по 10 выкупов

1. Заказы по ключевому слову гарантированы в течении 48 часов после того, как на заказ собрались все блогеры и он перешел в статус заказа «Исполнение»
2. Забор заказа с ПВЗ от 7 дней, так как блогеры живут в разных городах России
3. Отзыв, рилс, сторис в течении месяца. Но практика показывает, что рекламу и отзывы выпускают намного раньше заявленной даты

Наш сервис самостоятельно заполняет и генерирует вам таблицу excel со всей отчетностью от блогеров, вы можете наблюдать за заполнением на сайте в режиме реального времени.`}</span>,
  }
]

export const FAQ: FC<FAQPropsType> = ({ className }) => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number>()

  return (
    <div className={cn(styles.FAQ, className)}>
      <h2 className={styles.Title}>Частые вопросы</h2>
      <div className={styles.Questions}>
        {QUESTIONS.map(({ title, answer }, index) => (
          <div
            key={index}
            className={styles.Question}
            onClick={() => setOpenQuestionIndex(openQuestionIndex === index ? undefined : index)}
          >
            <div className={styles.QuestionHeader}>
              <span className={styles.QuestionTitle}>{title}</span>
              {openQuestionIndex === index ? <ArrowUp className={styles.QuestionIcon} /> : <ArrowDown className={styles.QuestionIcon} />}
            </div>
            <div className={cn(styles.QuestionAnswerContainer, openQuestionIndex === index && styles.QuestionAnswerContainer__isOpen)}>
              <div className={styles.QuestionAnswerContainer__overflow}>
                <div className={styles.QuestionAnswer}>
                  {answer}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
