import React, { FC, SVGProps, useMemo } from 'react'
import cn from 'classnames'

import { ReactComponent as Start } from 'src/shared/assets/start.svg'
import { ReactComponent as Stop } from 'src/shared/assets/stop.svg'
import { ReactComponent as Arrow } from 'src/shared/assets/arrow.svg'
import { ReactComponent as Telegram } from 'src/shared/assets/telegram.svg'
import { ReactComponent as TelegramSmall } from 'src/shared/assets/telegram_small.svg'
import { ReactComponent as Settings } from 'src/shared/assets/settings.svg'
import { ReactComponent as Bell } from 'src/shared/assets/bell.svg'
import { ReactComponent as ArrowDown } from 'src/shared/assets/arrow_down.svg'
import { ReactComponent as ArrowLeft } from 'src/shared/assets/arrow_left.svg'
import { ReactComponent as ArrowRight } from 'src/shared/assets/arrow_right.svg'
import { ReactComponent as ArrowUp } from 'src/shared/assets/arrow_up.svg'
import { ReactComponent as Plus } from 'src/shared/assets/plus.svg'
import { ReactComponent as Left } from 'src/shared/assets/left.svg'
import { ReactComponent as Upload } from 'src/shared/assets/upload.svg'
import { ReactComponent as CheckboxTrue } from 'src/shared/assets/checkbox_true.svg'
import { ReactComponent as CheckboxFalse } from 'src/shared/assets/checkbox_false.svg'

import styles from './Icon.module.scss'

export interface IconPropsType extends SVGProps<SVGSVGElement> {
  name: 'start'
      | 'stop'
      | 'arrow'
      | 'arrowLeft'
      | 'arrowRight'
      | 'arrowDown'
      | 'arrowUp'
      | 'telegram'
      | 'telegram-small'
      | 'settings'
      | 'bell'
      | 'plus'
      | 'left'
      | 'upload'
      | 'checkbox-true'
      | 'checkbox-false'
}

export const Icon: FC<IconPropsType> = ({ className, name, ...props }) => {
  const IconComponent = useMemo(() => {
    switch (name) {
      case 'start':
        return Start
      case 'stop':
        return Stop
      case 'arrow':
        return Arrow
      case 'arrowLeft':
        return ArrowLeft
      case 'arrowRight':
        return ArrowRight
      case 'arrowDown':
        return ArrowDown
      case 'arrowUp':
        return ArrowUp
      case 'telegram':
        return Telegram
      case 'telegram-small':
        return TelegramSmall
      case 'settings':
        return Settings
      case 'bell':
        return Bell
      case 'plus':
        return Plus
      case 'left':
        return Left
      case 'upload':
        return Upload
      case 'checkbox-true':
        return CheckboxTrue
      case 'checkbox-false':
        return CheckboxFalse
      default:
        throw new Error(`Иконка с именем ${name} не найдена`)
    }
  }, [name])

  return <IconComponent className={cn(styles.Icon, className)} {...props} />
}
