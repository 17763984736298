import React, { FC, ReactNode } from 'react'

import styles from './Blogger.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

export interface BloggerPropsType {
  name: string
  photoUrl: string
  instagramLink: string
  subscribersCount: number
  reach: number
  children?: ReactNode
}

export const Blogger: FC<BloggerPropsType> = ({ name, photoUrl, instagramLink, subscribersCount, reach, children }) => {
  return (
    <div className={styles.Blogger}>
      <div className={styles.Header}>
        <img className={styles.Image} src={photoUrl}/>
        <div className={styles.Info}>
          <span className={styles.Name}>{name}</span>
          <span className={styles.InstagramLink}>{instagramLink}</span>
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.Item}>
          <span className={styles.Title}>Подписчики:</span>
          <span className={styles.Value}>{numberFormat.format(subscribersCount)}</span>
        </div>
        <div className={styles.Item}>
          <span className={styles.Title}>Средний охват:</span>
          <span className={styles.Value}>{numberFormat.format(reach)}</span>
        </div>
        <div className={styles.Item}>
          <span className={styles.Title}>Тематика:</span>
          <div className={styles.Tags}>
            <div className={styles.Tag}>Дети</div>
            <div className={styles.Tag}>Отдых</div>
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
