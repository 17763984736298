/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CustomerInfoDto {
  /** ИНН заказчика */
  inn: string
}

export interface SignUpDto {
  /**
   * Номер телефона пользователя
   * @example "+79931234567"
   */
  phone: string
  /**
   * Пароль
   * @default "QWErty123"
   */
  password: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName?: string
  /**
   * User role - "CUSTOMER" | "BLOGER"
   * @default "CUSTOMER"
   */
  role: string
  /** Information about the customer, the filling is checked if the role "CUSTOMER" */
  customer: CustomerInfoDto
}

export interface CommonMessageResponse {
  /** Message Text */
  message: string
  /** Error code */
  code?: number
}

export interface SignInDto {
  /**
   * Номер телефона пользователя
   * @example "+79931234567"
   */
  phone: string
  /**
   * Пароль
   * @default "QWErty123"
   */
  password: string
  /** Уникальный ключ клиента */
  client: string
}

export interface SignInUserResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** User phone */
  phone: string
  /** User first name */
  firstName: string
  /** User last name */
  lastName: string
  /** Moderator verification indicator */
  status: string
  /** User role */
  role: string
}

export interface SignInResponseDto {
  /** First token */
  accessToken: string
  /** User info */
  user: SignInUserResponseDto
}

export interface BankDetailsDto {
  /** Id */
  id: number
  /** Наименование банка */
  bankName: string
  /** Номер счета */
  accountNumber: string
  /**
   * Номер телефона счета пользователя
   * @example "+79931234567"
   */
  accountPhone: string
}

export interface Topic {
  /** Id темы */
  topicId: number
}

export interface Topics {
  /** Перечисление тем, интересных блогеру для ДОБАВЛЕНИЯ */
  create: Topic[]
  /** Перечисление тем, интересных блогеру для УДАЛЕНИЯ */
  delete: Topic[]
}

export interface Screenshots {
  /** Id */
  id: number
  /** Скриншот поста */
  link: string
  /** Описание к скриншоту */
  description?: string
}

export interface UpsertBloggerDto {
  /** Ссылка на инстаграм блогера */
  instagramLink?: string
  /** Город проживания */
  city?: string
  /** Страна проживания */
  country?: string
  /** Количество подписчиков */
  followersCount?: number
  /** Информация по банковским реквизитам */
  bankDetails?: BankDetailsDto[]
  /** Интересуюшие тематики */
  blogTopics?: Topics
  /** Интересуюшие тематики */
  screenshots?: Screenshots[]
}

export interface UserActivateDto {
  /** Количество подписчиков в инстаграм */
  followersCount?: number
  /** Количество подписчиков в инстаграм */
  passportVerif?: boolean
  /**
   * Тип заказа - "ACTIVATED" | "NOT_ACTIVATED" | "INCOMPLETE_DATA" | "CANCELED"
   * @default "NOT_ACTIVATED"
   */
  status?: string
}

export interface PaginateResultDto {
  count: number
  total: number
  data: string[]
}

export interface UserListResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  email?: string
  phone?: string
  firstName?: string
  lastName?: string
  tgUsername?: string
  status?: string
  role?: string
}

export interface BankInfoResponseDto {
  /** Id */
  id: number
  /** Наименование банка */
  bankName: string
  /** Номер счета */
  accountNumber: string
  /** Номер телефона для банковского перевода */
  accountPhone: string
}

export interface BlogerResponseDto {
  /** Id */
  id: number
  /** Ссылка на инстаграмм */
  instagramLink: string
}

export interface CustonemrResponseDto {
  /** Id */
  id: number
  /** ИНН заказчика */
  inn?: string
}

export interface UserResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** E-mail */
  email: string
  /** Роль */
  role: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName: string
  /** Номер телефона пользователя */
  phone: string
  /** Телеграм username */
  tgUsername: string
  /** Статус состояния пользователя */
  status: string
  /** Информация по банковским данным */
  bankAccount: BankInfoResponseDto
  /** Информация блогера */
  bloger?: BlogerResponseDto
  /** Информация заказчика */
  customer?: CustonemrResponseDto
}

export interface BlackListDto {
  /** Id пользователя для блокировки. Поле необязательное */
  userId?: number
  /** Ссылка на инстаграмм блоггера */
  instagramLink?: string
  /** Номер телефона пользователя */
  phone?: string
  /** Номер телефона пользователя для банковских переводов */
  accountPhone?: string
  /** Номер банковского счета пользователя */
  accountNumber?: string
  /** Описание причины блокировки */
  comment: string
}

export interface BlacklistResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на инстаграмм блоггера */
  instagramLink?: string
  /** Номер телефона пользователя */
  phone?: string
  /** Номер телефона пользователя для банковских переводов */
  accountPhone?: string
  /** Номер банковского счета пользователя */
  accountNumber?: string
  /** Описание причины блокировки */
  comment: string
  /** Id пользователя для блокировки */
  userId?: number
}

export interface OrderListResponseDto {
  /** Id */
  id: number
  /** Ссылка на фото заказа */
  photoUrl?: string
  /** Ключевое слово поиска */
  keyWord?: string
  /** Ключевое слово поиска */
  commentIntegration?: string
  /** Ключевое слово поиска */
  commentMarketplace?: string
  /** Цена со скидкой на товар */
  discountedPrice?: number
  /** Максимальное количесво токенов */
  maxParticipants?: number
}

export interface OrderResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** utm метка товара */
  utmLabel?: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Цена товара со скидкой */
  discountedPrice?: number
  /** Артикул товара */
  sku?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
}

export interface CreateTopicDto {
  /** Название темы */
  name: string
}

export interface TopicResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Наименование тематики */
  name: string
}

export interface ProductVerificationDto {
  /** Перечисление id товаров, для подтверждения */
  approval?: number[]
  /** Перечисление id товаров, для отмены верификации */
  disapproval?: number[]
}

export interface OrderResponsesResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  blogerId: number
  orderId: number
  filterPrice: string
  productKey: string
  myDeliveries: string
  myPurchases: string
  shtrihCode: string
  comment: string
  realsLink: string
  /** @format date-time */
  realsDate: string
  storisLink: string
  storyStatistics: string
  isCompleted: boolean
  isPaid: boolean
}

export interface UpdateOrderResponseDto {
  filterPrice?: string
  productKey?: string
  myDeliveries?: string
  myPurchases?: string
  shtrihCode?: string
  comment?: string
  realsLink?: string
  realsDate?: string
  storisLink?: string
  storyStatistics?: string
  isCompleted?: boolean
  isPaid?: boolean
}

export interface CreateOrderDto {
  /**
   * Сборка ("AUTOMATIC" | "MANUAL")
   * @default "AUTOMATIC"
   */
  collection?: string
  /** Максимальное количество участвующих блогеров */
  maxParticipants: number
  /** UTM-метка */
  utmLabel?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Ключевое слово */
  keyWord: string
  /** id продукта */
  productId: number
  /** Перечисление тем для заказов */
  topics: Topic[]
}

export interface ResponsesManagementDto {
  /** Причина отказа */
  comment?: string
  /** Id`s одобренных блогеров */
  confirmed?: string[]
  /** Id`s отклоненных блогеров */
  rejected?: string[]
}

export interface CreateProductDto {
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
  /** Наименование товара */
  title: string
  /** Описание товара */
  description: string
  /** Артикул товара */
  article: string
  /** Цена на товар со скидкой */
  discountPrice: number
  /** Цена на товар */
  price: number
}

export interface ProductResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Артикул товара */
  article: string
  /** Наименование товара */
  title: string
  /** Описание товара */
  description: string
  /** Цена товара */
  price: number
  /** Цена товара со скидкой */
  discountPrice: number
  /** Актуальность товара */
  isActual: boolean
  /** Статус верификации товара */
  isVerified: boolean
  /** Id Заказчика */
  customerId: number
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
}

export interface UpdateProductDto {
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
  /** Наименование товара */
  title: string
  /** Описание товара */
  description: string
  /** Артикул товара */
  article: string
  /** Цена на товар со скидкой */
  discountPrice: number
  /** Цена на товар */
  price: number
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Admin API
 * @version 1.0.0
 * @contact
 *
 * Описание API, предназначенного для обеспечения функциональности приложения системного администрирования.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignUpDto
     * @request POST:/api/auth/sign-up
     */
    authControllerSignUpDto: (data: SignUpDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse>({
        path: `/api/auth/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignInDto
     * @request POST:/api/auth/sign-in
     */
    authControllerSignInDto: (data: SignInDto, params: RequestParams = {}) =>
      this.request<SignInResponseDto, CommonMessageResponse>({
        path: `/api/auth/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerUpsert
     * @request PATCH:/api/blogger
     * @secure
     */
    bloggerControllerUpsert: (data: UpsertBloggerDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/blogger`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerGetProfile
     * @request GET:/api/blogger/profile
     * @secure
     */
    bloggerControllerGetProfile: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/blogger/profile`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerUserActivated
     * @request POST:/api/management/users/{userId}
     * @secure
     */
    managementControllerUserActivated: (userId: number, data: UserActivateDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetUserById
     * @request GET:/api/management/users/{userId}
     * @secure
     */
    managementControllerGetUserById: (userId: number, params: RequestParams = {}) =>
      this.request<UserResponseDto, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetAllUsers
     * @request GET:/api/management/users
     * @secure
     */
    managementControllerGetAllUsers: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Тип заказа - "ACTIVATED" | "NOT_ACTIVATED" | "INCOMPLETE_DATA" | "CANCELED" */
        status?: string
        /** Роли участников - "ADMIN" | "BLOGER" | "CUSTOMER" | "MODERATOR" */
        role?: string
        /** Заполненность данных для блогера */
        isFullInfo?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: UserListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerPushToBlacklist
     * @request POST:/api/management/blacklist
     * @secure
     */
    managementControllerPushToBlacklist: (data: BlackListDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/blacklist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetBlacklist
     * @request GET:/api/management/blacklist
     * @secure
     */
    managementControllerGetBlacklist: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Поле поиска. Возможен поиска по комментариям, инстаграмму */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: BlacklistResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/blacklist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetAllOrders
     * @request GET:/api/management/orders
     * @secure
     */
    managementControllerGetAllOrders: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: OrderListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetOrderById
     * @request GET:/api/management/orders/{orderId}
     * @secure
     */
    managementControllerGetOrderById: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderResponseDto, void | CommonMessageResponse>({
        path: `/api/management/orders/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerCancelOrder
     * @request GET:/api/management/orders/{orderId}/cancel
     * @secure
     */
    managementControllerCancelOrder: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/orders/${orderId}/cancel`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerAddTopic
     * @request POST:/api/management/topics
     * @secure
     */
    managementControllerAddTopic: (data: CreateTopicDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/topics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllTopics
     * @request GET:/api/management/topics
     * @secure
     */
    managementControllerFindAllTopics: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Поле поиска */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: TopicResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/topics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerRemoveTopic
     * @request DELETE:/api/management/topics/{id}
     * @secure
     */
    managementControllerRemoveTopic: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/topics/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerVerificationProduct
     * @request POST:/api/management/products
     * @secure
     */
    managementControllerVerificationProduct: (data: ProductVerificationDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllProducts
     * @request GET:/api/management/products
     * @secure
     */
    managementControllerFindAllProducts: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        isActual?: boolean
        isVerified?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: TopicResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerCreate
     * @request POST:/api/orders/{orderId}/responses
     * @secure
     */
    orderResponsesControllerCreate: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAllResponses
     * @request GET:/api/orders/{orderId}/responses
     * @secure
     */
    ordersControllerFindAllResponses: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderResponsesResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerFindAll
     * @request GET:/api/orders/responses
     * @secure
     */
    orderResponsesControllerFindAll: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: OrderResponsesResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/orders/responses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerFindOne
     * @request GET:/api/orders/responses/{id}
     * @secure
     */
    orderResponsesControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<OrderResponsesResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerUpdate
     * @request PATCH:/api/orders/responses/{id}
     * @secure
     */
    orderResponsesControllerUpdate: (id: number, data: UpdateOrderResponseDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerRemove
     * @request DELETE:/api/orders/responses/{id}
     * @secure
     */
    orderResponsesControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerCreate
     * @request POST:/api/orders
     * @secure
     */
    ordersControllerCreate: (data: CreateOrderDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAll
     * @request GET:/api/orders
     * @secure
     */
    ordersControllerFindAll: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: OrderListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindOne
     * @request GET:/api/orders/{id}
     * @secure
     */
    ordersControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<OrderResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerResponsesManagement
     * @request PATCH:/api/orders/{orderId}/responses-management
     * @secure
     */
    ordersControllerResponsesManagement: (orderId: number, data: ResponsesManagementDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses-management`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerCreate
     * @request POST:/api/products
     * @secure
     */
    productsControllerCreate: (data: CreateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerFindAll
     * @request GET:/api/products
     * @secure
     */
    productsControllerFindAll: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        isActual?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: ProductResponseDto[]
        },
        CommonMessageResponse | void
      >({
        path: `/api/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerFindOne
     * @request GET:/api/products/{id}
     * @secure
     */
    productsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<ProductResponseDto, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerUpdate
     * @request PATCH:/api/products/{id}
     * @secure
     */
    productsControllerUpdate: (id: number, data: UpdateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerRemove
     * @request DELETE:/api/products/{id}
     * @secure
     */
    productsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
