import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { TextareaFormField } from 'src/shared/ui/TextareaFormField'
// TODO Почему-то не работает импорт из src/shared/ui/CabinetButton
import { CabinetButton } from 'src/shared/ui/CabinetButton/CabinetButton'
import { Uploader } from 'src/shared/ui/Uploader'
import { Field } from 'src/shared/ui/Field'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'

import styles from './CabinetProductsNew.module.scss'
import { AdminAPI, CommonMessageResponse, CreateProductDto, HttpResponse, StorageAPI } from 'src/shared/api'

type CabinetProductsNewFormFields = Omit<CreateProductDto, 'photoUrl'>

export const CabinetProductsNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_PRODUCTS_ROUTE), [navigate])

  const methods = useForm<CabinetProductsNewFormFields>()
  const { handleSubmit, watch } = methods
  const values = watch()

  const [image, setImage] = useState<File>()
  const [isUploading, setIsUploading] = useState(false)

  const createProductMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateProductDto
  >({
    mutationFn: AdminAPI.api.productsControllerCreate,
  })
  const isLoading = createProductMutation.isPending || isUploading

  const onSubmit = useCallback(async (data: CabinetProductsNewFormFields) => {
    if (!image) {
      return
    }

    setIsUploading(true)
    let photoUrl
    try {
      photoUrl = await StorageAPI.upload(image)
    } finally {
      setIsUploading(false)
    }

    if (!photoUrl) {
      return
    }

    const createProductDto: CreateProductDto = {
      marketplaceUrl: data.marketplaceUrl,
      title: data.title,
      description: data.description,
      article: data.article,
      discountPrice: Number(data.discountPrice),
      price: Number(data.price),
      photoUrl,
    }

    createProductMutation.mutate(createProductDto, {
      onSuccess: () => {
        navigate(CABINET_PRODUCTS_ROUTE, { replace: true })
      },
      onError: (error) => {
        if (error.error) {
          console.log(error.error)
        }
      }
    })
  }, [image])

  const isButtonDisabled = useMemo(() =>
    !values.marketplaceUrl ||
    !values.title ||
    !values.description ||
    !values.article ||
    !values.discountPrice ||
    !values.price ||
    !image ||
    isLoading
  , [values, image, isLoading])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <div className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Добавление товара</h1>
      </div>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.InputsContainer}>
            <TextInputFormField
              id="marketplaceUrl"
              name="marketplaceUrl"
              label="Ссылка на товар"
              placeholder="Ссылка на товар"
              kind="cabinet"
              disabled={isLoading}
            />
            <TextareaFormField
              id="title"
              name="title"
              label="Название"
              placeholder="Название"
              kind="cabinet"
              disabled={isLoading}
            />
            <TextInputFormField
              id="description"
              name="description"
              label="Описание"
              placeholder="Описание"
              kind="cabinet"
              disabled={isLoading}
            />
            <NumericInputFormField
              id="article"
              name="article"
              label="Артикул"
              placeholder="Артикул"
              kind="cabinet"
              disabled={isLoading}
            />
            <NumericInputFormField
              id="price"
              name="price"
              label="Актуальная цена"
              placeholder="Актуальная цена"
              kind="cabinet"
              unit="₽"
              disabled={isLoading}
            />
            <NumericInputFormField
              id="discountPrice"
              name="discountPrice"
              label="Цена для фильтра"
              placeholder="Цена для фильтра"
              kind="cabinet"
              unit="₽"
              disabled={isLoading}
            />
            <Field id="photo" label="Фотография товара" kind="cabinet">
              <Uploader onChange={setImage} isDisabled={isLoading} />
            </Field>
          </div>
          <CabinetButton className={styles.CabinetButton} disabled={isButtonDisabled}>
            Добавить товар
          </CabinetButton>
        </form>
      </FormProvider>
    </CabinetPageLayout>
  )
}
