export const passwordRules = {
  validate: (value: string) => {
    if (value.length < 8 || value.length > 32) {
      return 'Пароль должен быть не менее 8-ми символов в длину и не более 32'
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(value)) {
      return 'Пароль должен содержать символы латинского алфавита верхнего и нижнего региста и иметь в составе цифры'
    }
  },
}
