import React, { FC, MouseEvent, useCallback, useState } from 'react'
import cn from 'classnames'
import { TaskHeader } from 'src/widgets/TaskHeader'
import { CollectionType } from 'src/shared/config/types'
import { СollectionButton } from '../СollectionButton/СollectionButton'

import styles from './Task.module.scss'

interface TaskPropsType {
  photoUrl: string
  title: string
  collection: CollectionType
  onClick(): void
}

export const Task: FC<TaskPropsType> = ({ photoUrl, title, collection, onClick }) => {
  const [collectionButtonKind, setCollectionButtonKind] = useState<'start' | 'stop'>('stop')

  const handleCollectionButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setCollectionButtonKind(prevState => prevState === 'stop' ? 'start' : 'stop')
  }, [])

  const renderItem = useCallback((title: string, count: number, isWaitingForPayment = false) => (
    <div className={styles.Item}>
      <span className={styles.Title}>{title}</span>
      <span className={cn(styles.Count, isWaitingForPayment && styles.Count__isWaitingForPayment)}>{count}</span>
    </div>
  ), [])

  return (
    <div className={styles.Task} onClick={onClick}>
      <TaskHeader photoUrl={photoUrl} title={title} collection={collection} photoSize="big" />
      <div className={styles.Body}>
        {renderItem('Ждут подтверждения:', 12)}
        {renderItem('На первом этапе:', 6)}
        {renderItem('Ждут оплаты:', 6, true)}
        {renderItem('Подтвердили оплату:', 2)}
        {renderItem('Второй этап:', 2)}
        {renderItem('Третий этап:', 2)}
        {collection === CollectionType.MANUAL &&
          <СollectionButton
            className={styles.СollectionButton}
            kind={collectionButtonKind}
            onClick={handleCollectionButtonClick}
          />
        }
      </div>
    </div>
  )
}
