import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './CabinetPageLayout.module.scss'

interface CabinetPageLayoutPropsType {
  className?: string
  children: ReactNode
}

export const CabinetPageLayout: FC<CabinetPageLayoutPropsType> = ({ className, children }) => {
  return (
    <div className={cn(styles.CabinetPageLayout, className)}>
      {children}
    </div>
  )
}
