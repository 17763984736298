import { S3Client } from '@aws-sdk/client-s3'
import { PutObjectCommand } from '@aws-sdk/client-s3'
import { v4 as uuidv4 } from 'uuid'

const REGION = 'ru-central1'
const ENDPOINT = 'https://storage.yandexcloud.net'
const BUCKET = 'testmarketiq'

const s3Client = new S3Client({
  region: REGION,
  endpoint: ENDPOINT,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!
  },
})

export const StorageAPI = {
  upload: async (file: File) => {
    const Key = uuidv4()
    await s3Client.send(new PutObjectCommand({ Key, Bucket: BUCKET, Body: file }))

    return `${ENDPOINT}/${BUCKET}/${Key}`
  }
}
