import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'
import { Icon } from '../Icon'

import styles from './CabinetBackButton.module.scss'

export const CabinetBackButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...props }) => {
  return (
    <button
      className={cn(styles.CabinetBackButton, className)}
      {...props}
    >
      <Icon className={styles.Icon} name="left" />
      Назад
    </button>
  )
}
