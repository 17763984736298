import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './CabinetButton.module.scss'

interface CabinetButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
}

export const CabinetButton: FC<CabinetButtonPropsType> = ({ className, children, ...props }) => {
  return (
    <button className={cn(styles.CabinetButton, className)} {...props}>
      {children}
    </button>
  )
}
