import React, { FC } from "react";
import Logo from 'src/shared/assets/logo.svg'

import styles from './Footer.module.scss'

export const Footer: FC  = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.Container}>
        <img className={styles.Logo} src={Logo}/>
        <a className={styles.Link} href="https://t.me/rozetked" target="_blank" rel="noreferrer">
          Договор
        </a>
      </div>
    </div>
  )
}
