import React, { FC, useCallback } from "react";

import styles from './HeaderButtons.module.scss'
import cn from 'classnames'
import { LandingButton } from "src/shared/ui/LandingButton";
import { TelegramLink } from "../TelegramLink/TelegramLink";
import { useNavigate } from "react-router";
import { BLOGGER_REGISTRATION_ROUTE, CUSTOMER_REGISTRATION_ROUTE } from "src/shared/config/consts";

export interface HeaderButtonsPropsType {
  className: string
  type: 'customer' | 'blogger'
}

export const HeaderButtons: FC<HeaderButtonsPropsType> = ({ className, type }) => {
  const navigate = useNavigate()

  const handleCustomerRegistrationClick = useCallback(() => navigate(CUSTOMER_REGISTRATION_ROUTE), [])

  const handleBloggerRegistrationClick = useCallback(() => navigate(BLOGGER_REGISTRATION_ROUTE), [])

  return (
    <div className={cn(styles.HeaderButtons, type === 'blogger' && styles.HeaderButtons__reverse, className)}>
      <div className={styles.Group}>
        <LandingButton
          className={styles.LandingButton}
          kind={type === 'customer' ? 'primary' : 'ghost'}
          onClick={handleCustomerRegistrationClick}
        >
          Регистрация селеров
        </LandingButton>
        <TelegramLink link="https://t.me/rozetked">
          ТГ канал для селеров
        </TelegramLink>
      </div>
      <div className={styles.Group}>
        <LandingButton
          className={styles.LandingButton}
          kind={type === 'blogger' ? 'primary' : 'ghost'}
          onClick={handleBloggerRegistrationClick}
        >
          Регистрация блогеров
        </LandingButton>
        <TelegramLink link="https://t.me/rozetked">
          ТГ канал для блогеров
        </TelegramLink>
      </div>
    </div>
  )
}
