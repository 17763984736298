export const BASE_ROUTE = '/'
export const BLOGGER_LANDING_ROUTE = '/blogger'
export const BLOGGER_REGISTRATION_ROUTE = '/blogger/registartion'
export const CUSTOMER_LANDING_ROUTE = '/customer'
export const CUSTOMER_REGISTRATION_ROUTE = '/customer/registartion'
export const LOGIN_ROUTE = '/login'
export const CABINET_ROUTE = '/cabinet'
export const CABINET_PRODUCTS_ROUTE = `${CABINET_ROUTE}/products`
export const CABINET_PRODUCTS_NEW_ROUTE = `${CABINET_PRODUCTS_ROUTE}/new`
export const CABINET_TASKS_ROUTE = `${CABINET_ROUTE}/tasks`
export const CABINET_TASKS_NEW_ROUTE = `${CABINET_TASKS_ROUTE}/new`
export const CABINET_TASK_ROUTE = `${CABINET_TASKS_ROUTE}/:taskId`
