import React from 'react'
import { Navigate, type RouteObject } from 'react-router-dom'
import { CabinetLayout } from 'src/widgets/CabinetLayout'

import { BloggerLanding } from 'src/pages/BloggerLanding'
// TODO Почему-то не работает импорт из src/pages/BloggerRegistration
import { BloggerRegistration } from 'src/pages/BloggerRegistration/ui/BloggerRegistration/BloggerRegistration'
import { CustomerLanding } from 'src/pages/CustomerLanding'
import { CustomerRegistration } from 'src/pages/CustomerRegistration'
import { Login } from 'src/pages/Login'
import { Cabinet } from 'src/pages/Cabinet'
import { CabinetProducts } from 'src/pages/CabinetProducts'
import { CabinetTasks } from 'src/pages/CabinetTasks'
// TODO Почему-то не работает импорт из src/pages/CabinetTask
import { CabinetTask } from 'src/pages/CabinetTask/ui/CabinetTask/CabinetTask'
import { CabinetProductsNew } from 'src/pages/CabinetProductsNew'
import { CabinetTasksNew } from 'src/pages/CabinetTasksNew'

import {
  CABINET_ROUTE,
  BLOGGER_LANDING_ROUTE,
  BLOGGER_REGISTRATION_ROUTE,
  CUSTOMER_LANDING_ROUTE,
  CUSTOMER_REGISTRATION_ROUTE,
  LOGIN_ROUTE,
  CABINET_PRODUCTS_ROUTE,
  CABINET_TASKS_ROUTE,
  CABINET_PRODUCTS_NEW_ROUTE,
  CABINET_TASKS_NEW_ROUTE,
  CABINET_TASK_ROUTE
} from 'src/shared/config/consts'

export const useRootRoutes = (): RouteObject[] => {
  return [
    {
      path: '/',
      element: <Navigate replace to={BLOGGER_LANDING_ROUTE} />,
    },
    {
      path: BLOGGER_LANDING_ROUTE,
      element: <BloggerLanding />,
    },
    {
      path: BLOGGER_REGISTRATION_ROUTE,
      element: <BloggerRegistration />
    },
    {
      path: CUSTOMER_LANDING_ROUTE,
      element: <CustomerLanding />,
    },
    {
      path: CUSTOMER_REGISTRATION_ROUTE,
      element: <CustomerRegistration />,
    },
    {
      path: LOGIN_ROUTE,
      element: <Login />,
    },
    {
      path: CABINET_ROUTE,
      element: <CabinetLayout />,
      children: [
        {
          index: true,
          element: <Cabinet />
        },
        {
          path: CABINET_PRODUCTS_ROUTE,
          children: [
            {
              index: true,
              element: <CabinetProducts />,
            },
            {
              path: CABINET_PRODUCTS_NEW_ROUTE,
              element: <CabinetProductsNew />
            }
          ]
        },
        {
          path: CABINET_TASKS_ROUTE,
          children: [
            {
              index: true,
              element: <CabinetTasks />
            },
            {
              path: CABINET_TASKS_NEW_ROUTE,
              element: <CabinetTasksNew />
            },
            {
              path: CABINET_TASK_ROUTE,
              element: <CabinetTask />
            }
          ]
        }
      ]
    }
  ]
}
