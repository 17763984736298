import React, { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { CollectionType } from 'src/shared/config/types'
import { SelectFormField, SelectFormFieldPropsType } from 'src/shared/ui/SelectFormField'

import styles from './CollectionSelectFormField.module.scss'

type CollectionSelectFormFieldPropsType = Omit<SelectFormFieldPropsType, 'options'>

export const CollectionSelectFormField: FC<CollectionSelectFormFieldPropsType> = (props) => {
  const { watch } = useFormContext()
  const collection = watch(props.name)

  const options = useMemo(() => [
    {
      value: CollectionType.AUTOMATIC,
      label: 'Автоматический сбор'
    },
    {
      value: CollectionType.MANUAL,
      label: 'Ручной сбор'
    }
  ], [])

  return (
    <div className={styles.CollectionSelectFormField}>
      <SelectFormField
        options={options}
        {...props}
      />
      {collection === CollectionType.AUTOMATIC &&
        <div className={styles.CollectionDescription}>
          <ul className={styles.List}>
            <li>Быстрое продвижение товара по ключевому слову</li>
            <li>Повышение ранжирование товара внутри маркетплейса</li>
            <li>Оптимальное решение с точки зрения эффективности и стоимости</li>
          </ul>
          <br/>
          Цена одного участника в автоматическом сборе - 1 интеграция
        </div>
      }
      {collection === CollectionType.MANUAL &&
       <div className={styles.CollectionDescription}>
          <ul className={styles.List}>
            <li>Полный контроль над выбором исполнителей</li>
            <li>Идеален для заданий, направленных больше на внешнюю рекламу</li>
            <li>Подходит для продвижения любых категорий товаров</li>
          </ul>
          <br/>
          Цена одного участника в ручном сборе - 3 интеграции
        </div>
      }
    </div>
  )
}
