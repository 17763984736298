import React, { FC } from 'react'

import { Field, FieldPropsType } from '../Field'
import { PhoneInput, PhoneInputPropsType } from '../PhoneInput'

export type PhoneInputFieldPropsType = Omit<FieldPropsType, 'children'> & PhoneInputPropsType

export const PhoneInputField: FC<PhoneInputFieldPropsType> = ({ className, kind, label, id, error, isRequired, ...rest }) => {
  return (
    <Field className={className} label={label} id={id} error={error} isRequired={isRequired}>
      <PhoneInput id={id} isInvalid={Boolean(error)} kind={kind} {...rest} />
    </Field>
  )
}
