import React, { FC } from 'react'
import cn from 'classnames'
import { CollectionType } from 'src/shared/config/types'
import ozon from 'src/shared/assets/ozon.png'

import styles from './TaskHeader.module.scss'

interface TaskHeaderPropsType {
  photoUrl: string
  photoSize: 'big' | 'small'
  title: string
  collection: CollectionType
}

export const TaskHeader: FC<TaskHeaderPropsType> = ({ photoUrl, photoSize, title, collection }) => {
  return (
    <div className={styles.TaskHeader}>
      <img className={cn(styles.Photo, photoSize === 'big' && styles.Photo__big, photoSize === 'small' && styles.Photo__small)} src={photoUrl} />
      <div className={styles.InfoBlock}>
        <span className={styles.Title}>{title}</span>
        <div className={styles.SubInfoBlock}>
          <div className={styles.Marketplace}>
            <img className={styles.Icon} src={ozon} />
            <span>Ozon</span>
          </div>
          <span className={cn(styles.Collection, collection === CollectionType.AUTOMATIC && styles.Collection__isAutomatic)}>
            {collection === CollectionType.AUTOMATIC ? 'Автоматический сбор' : 'Ручной сбор'}
          </span>
        </div>
      </div>
      <div className={styles.StatusBlock}>
        <span className={styles.Status}>Нужное количество собрано, задание активно</span>
        <span className={styles.Progress}>15 / 15</span>
      </div>
    </div>
  )
}
