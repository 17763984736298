import React, { FC } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './Cabinet.module.scss'

export const Cabinet: FC = () => {
  return (
    <CabinetPageLayout>
      <iframe
        className={styles.Iframe}
        src="https://www.youtube.com/embed/43nBqL5Bu9w?si=PsTienFLLAPZJCR7&amp;controls=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </CabinetPageLayout>
  )
}
