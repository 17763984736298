import React, { ForwardedRef, forwardRef, ReactElement } from 'react'
import cn from 'classnames'
import comment1 from 'src/shared/assets/comment_1.png'
import comment2 from 'src/shared/assets/comment_2.png'
import comment3 from 'src/shared/assets/comment_3.png'
import comment4 from 'src/shared/assets/comment_4.png'
import comment5 from 'src/shared/assets/comment_5.png'

import styles from './TheyTalkAboutUs.module.scss'

interface TheyTalkAboutUsPropsType {
  className: string
}

const TheyTalkAboutUsWithRef = (
  { className }: TheyTalkAboutUsPropsType,
  forwardedRef: ForwardedRef<HTMLDivElement>
): ReactElement<TheyTalkAboutUsPropsType> => {
  return (
    <div className={cn(styles.TheyTalkAboutUs, className)} ref={forwardedRef}>
      <h2 className={styles.Title}>О нас говорят</h2>
      <div className={styles.Comments}>
        <img className={styles.Comment1} src={comment1} alt='Комментарий 1' />
        <img className={styles.Comment2} src={comment2} alt='Комментарий 2' />
        <img className={styles.Comment3} src={comment3} alt='Комментарий 3' />
        <img className={styles.Comment4} src={comment4} alt='Комментарий 4' />
        <img className={styles.Comment5} src={comment5} alt='Комментарий 5' />
      </div>
    </div>
  )
}

export const TheyTalkAboutUs = forwardRef(TheyTalkAboutUsWithRef)
