import React, { FC } from "react";

import styles from './Description.module.scss'

export interface DescriptionPropsType {
  descriptionTitle: string
  descriptionText: string
}

export const Description: FC<DescriptionPropsType> = ({ descriptionTitle, descriptionText }) => {
  return (
    <div className={styles.Description}>
      <div className={styles.DescriptionTitle}>{descriptionTitle}</div>
      <div className={styles.DescriptionText}>{descriptionText}</div>
    </div>
  )
}
