import React, { FC } from 'react'
import styles from './Avatar.module.scss'

export const Avatar: FC = () => {
  return (
    <img
      className={styles.Avatar}
      src="https://s3-alpha-sig.figma.com/img/acbc/7904/6c1c8eaf2d616d536e3844acce38e01e?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=L~CfFwTGPLQ8Gl0p0EwPozGVunfG5Lrrf9Z930OPq4vrt~vwbrJX3RPOsSVPDUf32GH025tQzwrN7hl0kjYT7CPPFs-z5AHz9XLWlY2V-vLY785M1S8VdSedg-VnMO0TBi5vgapL1fmtxfaOa8QqcBZ4keL1BTx~qYDXfCEdkEFqxEElGe3jWm0OcE-opSthvrvAGIWl5R37j6AsiQXBrFcV40317c4JecGtkDhZcfBoeQ05k8KzK4wThk83gYlrY4CtEDoHYNdqShDf-CMWKb2H7B1RiSTWfeQRbRl~pk8IInchYG6Hf0g-4913uWUt-xdn5v7X0~CzzdgZ6I~zfQ__"
      alt="Аватар"
    />
  )
}
