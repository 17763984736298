import React, { FC, TextareaHTMLAttributes } from 'react'
import cn from 'classnames'

import styles from './Textarea.module.scss'

export interface TextareaPropsType extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  kind?: 'landing' | 'cabinet'
  isInvalid?: boolean
  isRequired?: boolean
}

export const Textarea: FC<TextareaPropsType> = ({ className, kind = 'landing', isInvalid = true, isRequired, ...rest }) => {
  return (
    <label
      className={cn(
        styles.Container,
        isInvalid && styles.isInvalid,
        kind === 'landing' && styles.Container__landing,
        kind === 'cabinet' && styles.Container__cabinet,
        className
      )}
    >
      <textarea
        className={styles.Textarea}
        required={isRequired}
        {...rest}
      />
    </label>
  )
}
