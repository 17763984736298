import React, { FC, useCallback, useRef } from 'react'
import cn from 'classnames'
import { Icon } from 'src/shared/ui/Icon'

import image1 from 'src/shared/assets/how_does_this_work_1.svg'
import image2 from 'src/shared/assets/how_does_this_work_2.svg'
import image3 from 'src/shared/assets/how_does_this_work_3.svg'
import image4 from 'src/shared/assets/how_does_this_work_4.svg'
import image5 from 'src/shared/assets/how_does_this_work_5.svg'
import backgroundGradient1 from 'src/shared/assets/how_does_this_work_background_gradient_1.svg'
import backgroundGradient2 from 'src/shared/assets/how_does_this_work_background_gradient_2.svg'


import styles from './HowDoesThisWork.module.scss'

const ITEMS = [
  {
    number: '01',
    title: 'Селер выбирает блогера',
    description: 'Среди блогеров, откликнувшихся на его товар',
    image: image1,
  },
  {
    number: '02',
    title: 'Блогер выкупает товар',
    description: 'И забирает товар с ПВЗ',
    image: image2,
  },
  {
    number: '03',
    title: 'Блогер оставляет отзыв',
    description: 'Развернутый рекомендательный отзыв с несколькими фото на МП',
    image: image3,
  },
  {
    number: '04',
    title: 'Блогер публикует рекламу',
    description: 'Согласно вашему техническому заданию',
    image: image4,
  },
  {
    number: '05',
    title: 'Предоставляется подробный отчет',
    description: 'Скрин отзыва с маркетплейса, статистика, вовлеченность, переходы на карточку вашего товара',
    image: image5,
  }
]

interface HowDoesThisWorkPropsType {
  className: string
}

export const HowDoesThisWork: FC<HowDoesThisWorkPropsType> = ({ className }) => {
  const listRef = useRef<HTMLDivElement>(null)

  const handleArrowLeftClick = useCallback(() => {
    if (!listRef.current) {
      return
    }

    const gap = parseInt(window.getComputedStyle(listRef.current).gap) || 0
    const itemWidth = listRef.current.children[0].clientWidth
    const possition = listRef.current.scrollLeft / (itemWidth + gap)
    const nextPossition = possition % 1 === 0 ? possition - 1 : Math.floor(possition)

    listRef.current?.scrollTo({
      left: nextPossition * (itemWidth + gap),
      behavior: "smooth",
    });
  }, [])

  const handleArrowRightClick = useCallback(() => {
    if (!listRef.current) {
      return
    }

    const gap = parseInt(window.getComputedStyle(listRef.current).gap) || 0
    const itemWidth = listRef.current.children[0].clientWidth
    const possition = listRef.current.scrollLeft / (itemWidth + gap)
    const nextPossition = possition % 1 === 0 ? possition + 1 : Math.ceil(possition)

    listRef.current?.scrollTo({
      left: nextPossition * (itemWidth + gap),
      behavior: "smooth",
    });
  }, [])

  const renderArrows = useCallback(() =>
    <div className={styles.Arrows}>
      <Icon className={styles.Arrow} name="arrowLeft" onClick={handleArrowLeftClick} />
      <Icon className={styles.Arrow} name="arrowRight" onClick={handleArrowRightClick}  />
    </div>,
  [handleArrowLeftClick, handleArrowRightClick])

  return (
    <div className={cn(styles.HowDoesThisWork, className)}>
      <img className={styles.BackgroundGradient1} src={backgroundGradient1} />
      <img className={styles.BackgroundGradient2} src={backgroundGradient2} />
      <div className={styles.Header}>
        <h2 className={styles.Title}>Как это работает?</h2>
        {renderArrows()}
      </div>
      <div className={styles.List} ref={listRef}>
        {ITEMS.map(({number, title, description, image}) => (
          <div key={number} className={styles.Item}>
            <div className={styles.ItemHeader}>
              <span className={styles.ItemTitle}>{title}</span>
              <span className={styles.ItemNumber}>{number}</span>
            </div>
            <span className={styles.ItemDescription}>{description}</span>
            <img className={styles.ItemImage} src={image} />
          </div>
        ))}
      </div>
      <div className={styles.Footer}>{renderArrows()}</div>
    </div>
  )
}
