import React, { FC } from 'react'
import cn from 'classnames'

import styles from './BloggersCount.module.scss'

interface BloggersCountPropsType {
  className: string
}

export const BloggersCount: FC<BloggersCountPropsType> = ({ className }) => {
  return (
    <div className={cn(styles.BloggersCount, className)}>
      <div className={styles.Container}>
        К нам присоединились уже <span className={styles.Numbers}>3678</span> блогеров
      </div>
    </div>
  )
}
