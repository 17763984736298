import React, { FC } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Input, InputPropsType } from '../Input'

export type TextInputFieldPropsType = Omit<FieldPropsType, 'children'> & InputPropsType

export const TextInputField: FC<TextInputFieldPropsType> = ({ className, kind, label, id, error, isRequired, ...rest }) => {
  return (
    <Field className={className} kind={kind} label={label} id={id} error={error} isRequired={isRequired}>
      <Input id={id} isInvalid={Boolean(error)} kind={kind} {...rest} />
    </Field>
  )
}
