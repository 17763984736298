import React, { FC } from "react";
import cn from 'classnames'

import { ReactComponent as Activity } from 'src/shared/assets/activity.svg'
import { ReactComponent as Heart } from 'src/shared/assets/heart.svg'
import { ReactComponent as ShieldDone } from 'src/shared/assets/shield_done.svg'

import styles from './Advantages.module.scss'

interface AdvantagesPropsType {
  className: string
}

export const Advantages: FC<AdvantagesPropsType> = ({ className }) => {
  return (
    <div className={cn(styles.Advantages, className)}>
      <h2 className={styles.Title}>
        Преимущества платформы
      </h2>
      <div className={styles.Items}>
        <div className={styles.Item}>
          <Heart />
          <div className={styles.ItemTitle}>
            Удобно
          </div>
          <div className={styles.ItemDescription}>
            Персональный менеджер возьмет на себя всю коммуникацию с блогерами, создание рекламной кампании, отчет в режиме реального времени по каждой рекламной интеграции для оценки эффективности
          </div>
        </div>
        <div className={styles.Item}>
          <ShieldDone />
          <div className={styles.ItemTitle}>
            Выгодно
          </div>
          <div className={styles.ItemDescription}>
            Итоговая стоимость рекламной кампании через платформу Marketiq будет выгоднее, чем через другие платформы: вы получите раскрутку вашей карточки по ключевому запросу, положительный развернутый отзыв на ваш товар, а также целую рекламную кампанию у блогера на страничке с его искренними рекомендациями к покупке вашего товара
          </div>
        </div>
        <div className={styles.Item}>
          <Activity />
          <div className={styles.ItemTitle}>
            Эффективно
          </div>
          <div className={styles.ItemDescription}>
            Увидите результаты нашего сервиса практически сразу же: карточка будет подниматься в топы, а благодаря рекламной компании блогера у себя на странице к вам придут еще большое новых покупателей
          </div>
        </div>
      </div>
    </div>
  )
}
