import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Logo } from 'src/shared/ui/Logo'
import { LOGIN_ROUTE } from 'src/shared/config/consts'
import { LandingButton } from 'src/shared/ui/LandingButton'

import styles from './Header.module.scss'

export interface HeaderPropsType {
  arrowButtonText: string
  arrowButtonLink: string
}

export const Header: FC<HeaderPropsType>  = ({ arrowButtonText, arrowButtonLink }) => {
  const navigate = useNavigate()

  const handleArrowButtonClick = useCallback(() => navigate(arrowButtonLink), [navigate, arrowButtonLink])

  const handleLoginClick = useCallback(() => navigate(LOGIN_ROUTE), [navigate])

  return (
    <div className={styles.Header}>
      <div className={styles.Container}>
        <Logo />
        <div className={styles.Buttons}>
          <LandingButton kind='ghost' icon='arrow' onClick={handleArrowButtonClick}>{arrowButtonText}</LandingButton>
          <LandingButton onClick={handleLoginClick}>Войти</LandingButton>
        </div>
      </div>
    </div>
  )
}
