import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { TaskHeader } from 'src/widgets/TaskHeader'
import { Tabs, TabType } from 'src/shared/ui/Tabs'
import { TASKS } from 'src/pages/CabinetTasks/ui/CabinetTasks/CabinetTasks'
import { CollectionType } from 'src/shared/config/types'
import { Blogger } from '../Blogger/Blogger'
import { RejectedBlogger } from '../RejectedBlogger/RejectedBlogger'
import { RespondedBlogger } from '../RespondedBlogger/RespondedBlogger'
import { WaitingForPaymentBlogger } from '../WaitingForPaymentBlogger/WaitingForPaymentBlogger'
import { PaymentSentBlogger } from '../PaymentSentBlogger/PaymentSentBlogger'
import { ProgressBlogger, StagesEnum } from '../ProgressBlogger/ProgressBlogger'
import { SubmittedTheReportBlogger } from '../SubmittedTheReportBlogger/SubmittedTheReportBlogger'

import styles from './CabinetTask.module.scss'

export const CabinetTask: FC = () => {
  const { taskId } = useParams()
  const task = TASKS.find(item => item.id === Number(taskId))!
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_TASKS_ROUTE), [navigate])

  const [activeTabId, setActiveTabId] = useState(task.collection === CollectionType.MANUAL ? '2' : '3')

  const tabs = useMemo<TabType[]>(() => {
    return [
      ...(task.collection === CollectionType.MANUAL ? [{
        tabId: '1',
        title: 'Отклонены',
        badge: 2,
      },
      {
        tabId: '2',
        title: 'Откликнулись',
        badge: 3,
      }] : []),
      {
        tabId: '3',
        title: 'Оформляют заказ',
        badge: 6,
      },
      {
        tabId: '4',
        title: 'Ждут оплаты',
        badge: 3,
      },
      {
        tabId: '5',
        title: 'Оплата отправлена',
        badge: 3,
      },
      {
        tabId: '6',
        title: 'Выполняют задание',
        badge: 3,
      },
      {
        tabId: '7',
        title: 'Сдали отчет',
        badge: 2,
      }
    ]
  }, [])

  return (
    <CabinetPageLayout>
      <div className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <TaskHeader
          photoUrl={task.photoUrl}
          title={task.title}
          collection={task.collection}
          photoSize="small"
        />
      </div>
      <Tabs
        className={styles.Tabs}
        activeTabId={activeTabId}
        tabs={tabs}
        onTabClick={setActiveTabId}
      />
      <div className={styles.Bloggers}>
        <RejectedBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          onConfirm={() => {}}
        />
        <RespondedBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          onConfirm={() => {}}
          onReject={() => {}}
        />
        <Blogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
        />
        <WaitingForPaymentBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          onClick={() => {}}
        />
        <PaymentSentBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          onClick={() => {}}
        />
        <ProgressBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          stage={StagesEnum.STAGE_1}
          onClick={() => {}}
        />
        <ProgressBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          stage={StagesEnum.STAGE_2}
          onClick={() => {}}
        />
        <SubmittedTheReportBlogger
          name="Виктория"
          photoUrl="https://s3-alpha-sig.figma.com/img/36c9/16c2/f8cb0749118e0484ff6de6f9f3e31da9?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o28Ye9MyXHFmSEB-QiQD8wJDGvTi3~Y-L-j5GJhrZTUhzn1LSZoDT1rZB4GBsTxyDYCLObxZ7TiJ6renzYrohjcpA8srge1FLMznxvfn4ln-xowS-yqdMXUoE3V~nbNbLTSjzoaAA167ftUzu71uQaYflchXuGuyxuU~4FJT0z5CbXwDj3EkU~1cf1jVkn2~b99UwoKwVFG3D8J5epRGgVizTAHDhai0~ATEhmh9kCI18TdA7E1FIvtl5lSK3yivBQ-OKBGqoUe0TIB3~q1BLsbycl7WHel52F2t96XsjGB-hp1WoTGdpVTnq1bBm-uB~tcVCePmwIvNwEk~ABFb1Q__"
          instagramLink="@mamavi_life"
          subscribersCount={19560}
          reach={6580}
          onClick={() => {}}
        />
      </div>
    </CabinetPageLayout>
  )
}
