import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'

import { Input, InputPropsType } from '../Input'

import styles from './Select.module.scss'
import { Icon } from '../Icon'

export interface SelectOptionType {
  value: string
  label: string
}

export interface SelectPropsType extends Pick<InputPropsType, 'className' | 'id' | 'isInvalid' | 'placeholder'> {
  value: string
  options: SelectOptionType[]
  onChange(value: string): void
}

export const Select: FC<SelectPropsType> = ({ className, id, isInvalid, placeholder, value, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const handleInputClick = useCallback(() => setIsOpen(prevState => !prevState), [])

  const handleClick = useCallback((event: MouseEvent) => {
    if (selectRef.current && event.target instanceof HTMLElement && !selectRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }, [])

  const handleSelect = useCallback((value: string) => {
    onChange(value)
    setIsOpen(false)
  }, [onChange])

  const inputValue = useMemo(() => options.find(item => item.value === value)?.label, [value, options])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    };
  });

  return (
    <div className={cn(styles.Select, className)} ref={selectRef}>
      <Input
        id={id}
        isInvalid={isInvalid}
        placeholder={placeholder}
        autoComplete="off"
        kind="cabinet"
        value={inputValue}
        readOnly
        postfix={<Icon name={isOpen ? "arrowUp" : "arrowDown"} />}
        onClick={handleInputClick}
      />
      {isOpen && (
        <div className={styles.Options}>
          {options.map(item => (
            <div key={item.value} className={styles.Option} onClick={() => handleSelect(item.value)}>
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
