import React, { FC } from 'react'
import cn from 'classnames'
import { Blogger, BloggerPropsType } from '../Blogger/Blogger'
import { BloggerButton } from '../BloggerButton/BloggerButton'

import styles from './ProgressBlogger.module.scss'

export enum StagesEnum {
  STAGE_1 = 1,
  STAGE_2 = 2,
}

interface ProgressBloggerPropsType extends BloggerPropsType {
  stage: StagesEnum
  onClick(): void
}

export const ProgressBlogger: FC<ProgressBloggerPropsType> = ({ stage, onClick, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      <div className={styles.Container}>
        <div className={cn(
          styles.ProgressBar,
          stage === StagesEnum.STAGE_1 && styles.ProgressBar__stage1,
          stage === StagesEnum.STAGE_2 && styles.ProgressBar__stage2
        )}>
          <div className={styles.Line} />
          <div className={styles.Line} />
          <div className={styles.Progress} />
        </div>
        <span className={styles.Text}>
          {stage === StagesEnum.STAGE_1 ? 'Делает выкуп' : 'Делает рекламу'}
        </span>
        <BloggerButton className={styles.Button} onClick={onClick}>Детали интеграции</BloggerButton>
      </div>
    </Blogger>
  )
}
