import React, { FC } from 'react'
import cn from 'classnames'

import image1 from 'src/shared/assets/how_we_help_1.png'
import image2 from 'src/shared/assets/how_we_help_2.png'
import image3 from 'src/shared/assets/how_we_help_3.png'
import image4 from 'src/shared/assets/how_we_help_4.png'
import image5 from 'src/shared/assets/how_we_help_5.png'
import image6 from 'src/shared/assets/how_we_help_6.png'
import image7 from 'src/shared/assets/how_we_help_7.png'
import image8 from 'src/shared/assets/how_we_help_8.png'
import image9 from 'src/shared/assets/how_we_help_9.png'
import image10 from 'src/shared/assets/how_we_help_10.png'
import image11 from 'src/shared/assets/how_we_help_11.png'

import styles from './HowWeHelp.module.scss'

interface HowWeHelpPropsType {
  className: string
}

export const HowWeHelp: FC<HowWeHelpPropsType> = ({ className }) => {
  return (
    <div className={cn(styles.HowWeHelp, className)}>
      <h2 className={styles.Title}>Как мы помогаем</h2>
      <div className={styles.Container}>
        <div className={styles.FirstImageBlock}>
          <img className={cn(styles.Image, styles.Image__extraRoundTop)} src={image1} />
          <img className={styles.Image} src={image2} />
          <img className={styles.Image} src={image3} />
          <img className={styles.Image} src={image4} />
          <img className={cn(styles.Image, styles.Image__extraRoundBottom)} src={image5} />
        </div>
        <div className={styles.SecondImageBlock}>
          <img className={cn(styles.Image, styles.Image__extraRoundTop)} src={image6} />
          <img className={cn(styles.Image, styles.Image__extraRoundTop)} src={image7} />
          <img className={cn(styles.Image, styles.Image__extraRoundBottom)} src={image8} />
          <img className={cn(styles.Image, styles.Image__extraRoundBottom)} src={image9} />
        </div>
        <div className={styles.ThirdImageBlock}>
          <img className={cn(styles.Image, styles.Image__extraRoundTop)} src={image10} />
          <div>
            <img className={styles.Image} src={image11} />
          </div>
        </div>
      </div>
    </div>
  )
}
