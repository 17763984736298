import React, { ReactElement } from 'react'
import { FieldValues } from 'react-hook-form'
import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'
import { ReactHookFormFieldType } from 'src/shared/config/types'
import { CheckboxField, CheckboxFieldPropsType } from '../CheckboxField'

type CheckboxFormFieldPropsType<TFieldValues extends FieldValues = FieldValues> = ReactHookFormFieldType<TFieldValues, CheckboxFieldPropsType>

export const CheckboxFormField = <TFieldValues extends FieldValues = FieldValues>(
  {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    ...props
  }: CheckboxFormFieldPropsType<TFieldValues>
): ReactElement<CheckboxFormFieldPropsType<TFieldValues>> => {
  const {
    field,
    error,
  } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return (
    <CheckboxField
      {...field}
      checked={Boolean(field.value)}
      error={error}
      {...props}
    />
  )
}
