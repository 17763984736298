import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from '../Blogger/Blogger'
import { BloggerButton } from '../BloggerButton/BloggerButton'

import styles from './SubmittedTheReportBlogger.module.scss'

interface SubmittedTheReportBloggerPropsType extends BloggerPropsType {
  onClick(): void
}

export const SubmittedTheReportBlogger: FC<SubmittedTheReportBloggerPropsType> = ({ onClick, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      <div className={styles.Container}>
        <span className={styles.Text}>
          Блогер выполнил все этапы задания. Проверьте их.
        </span>
        <BloggerButton onClick={onClick}>Детали интеграции</BloggerButton>
      </div>
    </Blogger>
  )
}
