import React, { FC } from 'react'
import { CABINET_TASKS_ROUTE, CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'

import { Logo } from '../Logo/Logo'
import { TelegramLink } from '../TelegramLink/TelegramLink'
import { Avatar } from '../Avatar/Avatar'
import { IconButton } from '../IconButton/IconButton'

import styles from './Header.module.scss'

export const Header: FC = () => {
  return (
    <div className={styles.Header}>
      <Logo />
      <div className={styles.Navigation}>
        <a className={styles.NavigationItem} href={CABINET_PRODUCTS_ROUTE}>Мои товары</a>
        <a className={styles.NavigationItem} href={CABINET_TASKS_ROUTE}>Мои задания (вёрстка)</a>
      </div>
      <div className={styles.Menu}>
        <TelegramLink />
        <IconButton icon="settings" />
        <IconButton icon="bell" />
        <Avatar />
      </div>
    </div>
  )
}
