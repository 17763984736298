import React, { FC, useCallback } from 'react'
import cn from 'classnames'
import { Icon } from 'src/shared/ui/Icon'

import styles from './TelegramButton.module.scss'

interface TelegramButtonPropsType {
  className: string
}

export const TelegramButton: FC<TelegramButtonPropsType> = ({ className }) => {
  const handleClick = useCallback(() => {
    window.open('https://t.me/rozetked');
  }, [])

  return (
    <div className={cn(styles.TelegramButton, className)} onClick={handleClick}>
      <div className={styles.Circle} />
      <Icon className={styles.Icon} name="telegram" />
      <span className={styles.Text}>Написать<br/>в телеграм</span>
    </div>
  )
}
