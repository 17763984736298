import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetAddButton } from 'src/shared/ui/CabinetAddButton'
import { CABINET_TASKS_NEW_ROUTE, CABINET_TASKS_ROUTE } from 'src/shared/config/consts'

import { Task } from '../Task/Task'

import styles from './CabinetTasks.module.scss'
import { CollectionType } from 'src/shared/config/types'

export const TASKS = [
  {
    id: 1,
    photoUrl: 'https://s3-alpha-sig.figma.com/img/b327/0219/d902421dd33021542b39c1b161a88fbf?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Rb2OdlUk2HRdoUNLwsWrLAcDBjqhVMnS2CATP-QgGTNzkJtM77V1PM~prshzbbQ97md28-~YriUsrymqkAWrtbhkNnl9Z42Yn19SClvqbgYLiKqgCi~CZuGKy5jyL-cioqdORi-XFCyDHaBJ4qKzs5FqoX3kMqY7JiP-MQvurCSoUZXDemPkf7L3kBfZtSbI4QCj~wx7KSS6oX1K-zg1T7xUsSoRSpd9rYd01YA9LP4OPihpKX-vsris1SbvvzaxEWl2bSUUgV2xTz5Ienae2jpw-zOmLQEAA4XBSAeX3haRXmtP9r3lfu-OhqJd-qqoVZOJPEOtVHrvwDxsR8bzIA__',
    title: 'Крем для рук и тела',
    collection: CollectionType.MANUAL
  },
  {
    id: 2,
    photoUrl: 'https://s3-alpha-sig.figma.com/img/625a/5162/f3c66073fe771ccc6a5f87f6793dda32?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=P~ulVbblWen0KkpOecIrKmJqAz1AxdwcLeoUpicaklGAJBcM-1SkKLAUmgq8WHGS9xdBcV4fwdG1qtPMx16MYygOlKVjjknpJpCdxCEYZnvZMe5t942ZhmvBQ3dbrujlK4bdbHoEsHeQupSCJdrcsW7omDLjpNiYSaR8HR~pGdm4D1EL2p52lgxE3WpQRZl6XgWI0ZcBD1UMWP4JWuCbfVtOBGezpfL8raBMak9wbdWm28D9aJfXhhcmtFI~lV7FYaR5IGYey2xjOkV1VmKME3uy6f-obYxRyaENDNNlC2n2n5AdXJO9fLpqKpG4zezqRgR29E66rYkB55VPwqcRvg__',
    title: 'BRAU кольцо серебро женское',
    collection: CollectionType.AUTOMATIC
  }
]

export const CabinetTasks: FC = () => {
  const navigate = useNavigate()
  const handleAddTaskClick = useCallback(() => navigate(CABINET_TASKS_NEW_ROUTE), [navigate])

  const openTask = useCallback((taskId: number) => navigate(`${CABINET_TASKS_ROUTE}/${taskId}`), [navigate])

  return (
    <CabinetPageLayout>
      <div className={styles.Header}>
        <h1 className={styles.Title}>Мои задания</h1>
        <CabinetAddButton onClick={handleAddTaskClick}>Добавить задание</CabinetAddButton>
      </div>
      <div className={styles.Tasks}>
        {TASKS.map(({ id, photoUrl, title, collection }) => (
          <Task
            key={id}
            photoUrl={photoUrl}
            title={title}
            collection={collection}
            onClick={() => openTask(id)}
          />
        ))}
      </div>
    </CabinetPageLayout>
  )
}
