import React, { FC, ReactNode, useMemo } from 'react'
import { Navigate } from 'react-router'
import { LOGIN_ROUTE } from 'src/shared/config/consts'
import { SignInUserResponseDto } from 'src/shared/api'

interface AuthPermissionPropsType {
  children: ReactNode
}

export const AuthPermission: FC<AuthPermissionPropsType> = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken')
  const user = localStorage.getItem('user')

  const userObject = user ? JSON.parse(user) as SignInUserResponseDto : undefined

  const hasPermission = useMemo(() => {
    if (!accessToken || !userObject) {
      return false
    }

    if (userObject.role === 'CUSTOMER') {
      return true
    }

    console.log('Личный кабинет доступен только для роли "CUSTOMER"')
    return false
  }, [])

  if (!hasPermission) {
    return <Navigate to={LOGIN_ROUTE} />
  }

  return <>{children}</>
}
