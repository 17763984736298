import React, { FC, useEffect, useRef, useState } from 'react'
import { Main } from 'src/widgets/Main'
import { LandingPage } from 'src/widgets/LandingPage'
import { AnyQuestions } from 'src/widgets/AnyQuestions'

import { BLOGGER_LANDING_ROUTE } from 'src/shared/config/consts'

import mainBackgroundGradient1 from 'src/shared/assets/main_background_gradient_1.svg'
import mainBackgroundGradient2 from 'src/shared/assets/main_background_gradient_2.svg'
import tariffsBackgroundGradient1 from 'src/shared/assets/tariffs_background_gradient_1.svg'
import tariffsBackgroundGradient2 from 'src/shared/assets/tariffs_background_gradient_2.svg'
import bottomBackgroundGradient from 'src/shared/assets/bottom_background_gradient.svg'

import { HeaderImages } from '../HeaderImages/HeaderImages'
import { Video } from '../Video/Video'
import { HowDoesThisWork } from '../HowDoesThisWork/HowDoesThisWork'
import { Advantages } from '../Advantages/Advantages'
import { Numbers } from '../Numbers/Numbers'
import { HowWeHelp } from '../HowWeHelp/HowWeHelp'
import { Tariffs } from '../Tariffs/Tariffs'
import { FAQ } from '../FAQ/FAQ'

import styles from './CustomerLanding.module.scss'

export const CustomerLanding: FC = () => {
  const tariffsRef = useRef<HTMLDivElement>(null)

  const [tariffsTop, setTariffsTop] = useState<number>()

  useEffect(() => {
    const onResize = () => {
      const tariffsRefBox = tariffsRef?.current?.getBoundingClientRect()

      if (tariffsRefBox) {
        setTariffsTop(tariffsRefBox.top + window.scrollY)
      }
    }
    window.addEventListener('resize', onResize);
    setTimeout(() => onResize(), 0);

    return () => window.removeEventListener('resize', onResize);
  }, []);


  return (
    <LandingPage
      className={styles.LandingPage}
      arrowButtonText="Блогерам"
      arrowButtonLink={BLOGGER_LANDING_ROUTE}
    >
      <div className={styles.Background}>
        <img className={styles.MainBackgroundGradient1} src={mainBackgroundGradient1} />
        <img className={styles.MainBackgroundGradient2} src={mainBackgroundGradient2} />
        {tariffsTop && (
          <>
            <img style={{ top: tariffsTop - 500 }} className={styles.TariffsBackgroundGradient1} src={tariffsBackgroundGradient1} />
            <img style={{ top: tariffsTop - 200 }} className={styles.TariffsBackgroundGradient2} src={tariffsBackgroundGradient2} />
          </>
        )}
        <img className={styles.BottomBackgroundGradient} src={bottomBackgroundGradient} />
      </div>
      <Main
        title={
          <>Продвигайте товары <span className="alphaText">Wildberries через рекламу</span> у блогеров</>
        }
        descriptionTitle="Как мы работаем:"
        descriptionText="Блогеры получают от вас товар по бартеру, а взамен выкупают его по ключевому запросу, оставляют положительный отзыв с фото, делают серию рекламных интеграций у себя в блоге со ссылкой на ваш товар и ваш бренд."
        type='customer'
      >
        <HeaderImages />
      </Main>
      <Video className={styles.Video} />
      <HowDoesThisWork className={styles.HowDoesThisWork} />
      <Advantages className={styles.Advantages} />
      <Numbers className={styles.Numbers} />
      <HowWeHelp className={styles.HowWeHelp} />
      <Tariffs className={styles.Tariffs} ref={tariffsRef} />
      <FAQ className={styles.FAQ} />
      <AnyQuestions className={styles.AnyQuestions} />
    </LandingPage>
  )
}
