import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Numbers.module.scss'

interface NumbersPropsType {
  className: string
}

export const Numbers: FC<NumbersPropsType> = ({ className }) => {
  return (
    <div className={cn(styles.Numbers, className)}>
      <h2 className={styles.Title}>
        Marketiq в цифрах
      </h2>
      <div className={styles.Items}>
        <div className={styles.ItemsGroup}>
          <div className={styles.Item}>
            <div className={styles.ItemNumber}>
              5 024
            </div>
            <div className={styles.ItemDescription}>
              Блогера
            </div>
          </div>
          <div className={styles.Item}>
            <div className={styles.ItemNumber}>
              1 045
            </div>
            <div className={styles.ItemDescription}>
              Брендов
            </div>
          </div>
          <div className={styles.Item}>
            <div className={styles.ItemNumber}>
              14 000
            </div>
            <div className={styles.ItemDescription}>
              Рекламных интеграций
            </div>
          </div>
        </div>
        <div className={styles.ItemsGroup}>
          <div className={styles.Item}>
            <div className={styles.ItemNumber}>
              15 675
            </div>
            <div className={styles.ItemDescription}>
              Товаров
            </div>
          </div>
          <div className={styles.Item}>
            <div className={styles.ItemNumber}>
              300 450 421
            </div>
            <div className={styles.ItemDescription}>
              Рекламных охватов
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
