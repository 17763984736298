import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetAddButton } from 'src/shared/ui/CabinetAddButton'
import { CABINET_PRODUCTS_NEW_ROUTE } from 'src/shared/config/consts'
import { AdminAPI, ProductResponseDto } from 'src/shared/api'

import { Product } from '../Product/Product'

import styles from './CabinetProducts.module.scss'

export const CabinetProducts: FC = () => {
  const navigate = useNavigate()
  const handleAddProductClick = useCallback(() => navigate(CABINET_PRODUCTS_NEW_ROUTE), [])

  const { data: productsData } = useQuery({
    queryKey: ['products'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.productsControllerFindAll({ page:  1, take: 200 }),
  })

  // TODO Разработать с типом ответа
  // @ts-ignore
  const products = useMemo<ProductResponseDto[]>(() => productsData?.data?.products ?? [], [productsData])

  return (
    <CabinetPageLayout>
      <div className={styles.Header}>
        <h1 className={styles.Title}>Мои товары</h1>
        <CabinetAddButton onClick={handleAddProductClick}>Добавить товар</CabinetAddButton>
      </div>
      <div className={styles.Products}>
        {products.map(({ id, photoUrl, title, price }) => (
          <Product
            key={id}
            photoUrl={photoUrl}
            title={title}
            price={price}
          />
        ))}
      </div>
    </CabinetPageLayout>
  )
}
