import React, { ChangeEventHandler, DragEventHandler, FC, MouseEventHandler, useCallback, useRef, useState } from 'react'
import cn from 'classnames'
import { Icon } from '../Icon'

import styles from './Uploader.module.scss'

interface UploaderPropsType {
  className?: string
  onChange(file: File): void
  isDisabled?: boolean
}

export const Uploader: FC<UploaderPropsType> = ({ className, onChange, isDisabled }) => {
  const [isActive, setIsActive] = useState(false)
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null | undefined>()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const fileHandler = useCallback((fileList: FileList) => {
    const image = fileList[0]

    onChange(image)

    const fileReader = new FileReader()
    fileReader.readAsDataURL(image)
    fileReader.onload = (fileReaderEvent: ProgressEvent<FileReader>) => {
      setImageSrc(fileReaderEvent?.target?.result)
    }
  }, [onChange])

  const handleClick = useCallback<MouseEventHandler>(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [])

  const handleDragLeave = useCallback(() => setIsActive(false), [])

  const handleDragOver = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
    event.preventDefault()
    setIsActive(true)
  }, [])

  const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
    event.preventDefault()

    if (event.type === 'drop' && event.dataTransfer.files) {
      setIsActive(false)
      fileHandler(event.dataTransfer.files)
    }
  }, [])

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    if (event.target.files) {
      fileHandler(event.target.files)
    }

    event.target.value = ''
  }, [])

  return (
    <div
      className={cn(styles.Uploader, isActive && styles.isActive, isDisabled && styles.isDisabled, className)}
      onClick={handleClick}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        accept="image/*"
        capture="environment"
        className={styles.HiddenInput}
        ref={fileInputRef}
        type="file"
        onChange={handleInputChange}
        disabled={isDisabled}
      />
      {imageSrc
        ? <img className={styles.Image} src={imageSrc as string} />
        : <div className={styles.Placeholder}>
            <Icon name="upload" />
            Загрузить фото
          </div>
        }
    </div>
  )
}
