import React, { FC } from 'react'
import cn from 'classnames'

import product1 from 'src/shared/assets/product_1.png'
import product2 from 'src/shared/assets/product_2.png'
import product3 from 'src/shared/assets/product_3.png'
import product4 from 'src/shared/assets/product_4.png'

import styles from './Products.module.scss'

interface ProductsPropsType {
  className: string
}

export const Products: FC<ProductsPropsType> = ({ className }) => {
  return (
    <div className={cn(styles.Products, className)}>
      <div className={styles.Item}>
        <img className={styles.ItemImage} src={product1} alt="Куртка" />
        <span className={styles.ItemTitle}>Куртка</span>
        <span className={styles.ItemPrice}>Цена</span>
        <button className={styles.ItemButton}>Получить</button>
      </div>
      <div className={styles.Item}>
        <img className={styles.ItemImage} src={product2} alt="Топик" />
        <span className={styles.ItemTitle}>Топик</span>
        <span className={styles.ItemPrice}>Цена</span>
        <button className={styles.ItemButton}>Получить</button>
      </div>
      <div className={styles.Item}>
        <img className={styles.ItemImage} src={product3} alt="Органайзер" />
        <span className={styles.ItemTitle}>Органайзер</span>
        <span className={styles.ItemPrice}>Цена</span>
        <button className={styles.ItemButton}>Получить</button>
      </div>
      <div className={styles.Item}>
        <img className={styles.ItemImage} src={product4} alt="Топик" />
        <span className={styles.ItemTitle}>Топик</span>
        <span className={styles.ItemPrice}>Цена</span>
        <button className={styles.ItemButton}>Получить</button>
      </div>
    </div>
  )
}
