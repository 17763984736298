import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import cn from 'classnames'
import { Icon, IconPropsType } from '../Icon'

import styles from './LandingButton.module.scss'

interface LandingButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  kind?: 'primary' | 'ghost'
  icon?: IconPropsType['name']
}

export const LandingButton: FC<LandingButtonPropsType> = ({ className, children, kind = 'primary', icon, ...props }) => {
  return (
    <button
      className={cn(
        styles.LandingButton,
        kind === 'ghost' && styles.LandingButton__kindGhost,
        icon && styles.LandingButton__withIcon,
        className
      )}
      {...props}
    >
      {children}
      {icon && <Icon className={styles.Icon} name={icon} />}
    </button>
  )
}
