import React, { FC } from "react";
import headerImage1 from 'src/shared/assets/header_1.png'
import headerImage2 from 'src/shared/assets/header_2.png'
import headerImage3 from 'src/shared/assets/header_3.png'

import { ImageCard } from "../ImageCard/ImageCard";

import styles from './HeaderImages.module.scss'

export const HeaderImages: FC = () => {
  return (
    <div className={styles.HeaderImages}>
      <ImageCard className={styles.TopImageCard} image={headerImage2} rating="5.0" size="big"/>
      <div className={styles.BackgroundImages}>
        <ImageCard image={headerImage1} rating="4.8" size="small"/>
        <ImageCard image={headerImage3} rating="4.9" size="small"/>
      </div>
    </div>
  )
}
