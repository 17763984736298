import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from '../Blogger/Blogger'
import { BloggerButton } from '../BloggerButton/BloggerButton'

import styles from './RespondedBlogger.module.scss'

interface RespondedBloggerPropsType extends BloggerPropsType {
  onConfirm(): void
  onReject(): void
}

export const RespondedBlogger: FC<RespondedBloggerPropsType> = ({ onConfirm, onReject, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      <div className={styles.Container}>
        <BloggerButton onClick={onConfirm}>Подтвердить участника</BloggerButton>
        <BloggerButton kind="ghost" onClick={onReject}>Отклонить участника</BloggerButton>
      </div>
    </Blogger>
  )
}
