import React, { FC } from 'react'
import cn from 'classnames'
import { ReactComponent as BigStar } from 'src/shared/assets/big_star.svg'
import { ReactComponent as Checkbox } from 'src/shared/assets/checkbox.svg'

import styles from './Tariff.module.scss'

interface TariffPropsType {
  className?: string
  title: string
  integrations: string
  items: string[]
  price: string
  isPremium?: boolean
}

export const Tariff: FC<TariffPropsType> = ({ className, title, integrations, items, price, isPremium }) => {
  return (
    <div className={cn(styles.Tariff, isPremium && styles.Tariff__isPremium, className)}>
      <h3 className={styles.Title}>{title}</h3>
      <span className={styles.Integrations}>{integrations}</span>
      <div className={styles.List}>
        {items.map(item => (
          <div className={styles.Item} key={item}>
            <Checkbox className={styles.Checkbox}/>{item}
          </div>
        ))}
      </div>
      <span className={styles.Price}>{price}</span>
      <span className={styles.SubPrice}>За одну интеграцию</span>
      {isPremium &&
        <div className={styles.BigStarContainer}>
          <BigStar />
        </div>
      }
    </div>
  )
}
