import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Video.module.scss'

interface VideoPropsType {
  className: string
}

export const Video: FC<VideoPropsType>  = ({ className }) => {
  return (
    <div className={cn(styles.Video, className)}>
      <iframe
        className={styles.Iframe}
        src="https://www.youtube.com/embed/43nBqL5Bu9w?si=PsTienFLLAPZJCR7&amp;controls=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </div>
  )
}
