import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import cn from 'classnames'
import { Icon } from '../Icon'

import styles from './CabinetAddButton.module.scss'

interface CabinetAddButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {

}

export const CabinetAddButton: FC<CabinetAddButtonPropsType> = ({ className, children, ...props }) => {
  return (
    <button
      className={cn(styles.CabinetAddButton, className)}
      {...props}
    >
      <Icon className={styles.Icon} name="plus" />
      {children}
    </button>
  )
}
