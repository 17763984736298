import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './AuthBlock.module.scss'

interface AuthBlockPropsType {
  className?: string,
  title: string,
  children: ReactNode,
}

export const AuthBlock: FC<AuthBlockPropsType> = ({ className, title, children }) => {
  return (
    <div className={cn(styles.AuthBlock, className)}>
      <h1 className={styles.Title}>{title}</h1>
      <div className={styles.Container}>
        {children}
      </div>
    </div>
  )
}
