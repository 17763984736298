import React, { FC, InputHTMLAttributes } from 'react'
import cn from 'classnames'
import { Icon } from '../Icon'

import styles from './Checkbox.module.scss'

export interface CheckboxPropsType extends InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean
}

export const Checkbox: FC<CheckboxPropsType> = ({ className, children,  ...props }) => {
  const { checked = false } = props

  const iconName = checked ? 'checkbox-true' : 'checkbox-false'

  return (
    <label className={cn(styles.Checkbox, className)}>
      <input
        className={styles.Input}
        type="checkbox"
        {...props}
      />
      <Icon className={styles.Icon} name={iconName} />
      {children && (
        <span className={styles.Text}>
          {children}
        </span>
      )}
    </label>
  )
}
