import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './Field.module.scss'

export interface FieldPropsType {
  className?: string
  kind?: 'landing' | 'cabinet'
  label?: string
  id: string
  error?: string
  children: ReactNode
  isRequired?: boolean
  withHiddenLabel?: boolean
}

export const Field: FC<FieldPropsType> = ({ className, kind = "landing", label, id, error, children, isRequired, withHiddenLabel }) => {
  return (
    <div className={cn(
      styles.Field,
      kind === 'landing' && styles.Field__landing,
      kind === 'cabinet' && styles.Field__cabinet,
      className
    )}>
      {!withHiddenLabel && <label
        className={styles.Label} htmlFor={id}>
        {label}
        {isRequired && <span className={styles.Star}>*</span>}
      </label>}
      {children}
      {error && <span className={styles.Error}>{error}</span>}
    </div>
  )
}
