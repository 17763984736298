import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'
import { Icon, IconPropsType } from 'src/shared/ui/Icon'

import styles from './IconButton.module.scss'

interface IconButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconPropsType['name']
}

export const IconButton: FC<IconButtonPropsType> = ({ className, icon, ...rest }) => {
  return (
    <button className={cn(styles.IconButton, icon === 'bell' && styles.IconButton__bell, className)} {...rest}>
      <Icon name={icon} />
    </button>
  )
}
