import React, { FC } from 'react'
import { Icon } from 'src/shared/ui/Icon'

import styles from './TelegramLink.module.scss'

export const TelegramLink: FC = () => {
  return (
    <a className={styles.TelegramLink} href="https://t.me/rozetked" target="_blank" rel="noreferrer">
      Телеграм-бот<Icon name="telegram-small" />
    </a>
  )
}
