import React, { FC, useCallback} from 'react'
import { useNavigate } from 'react-router'
import cn from 'classnames'
import { BASE_ROUTE } from 'src/shared/config/consts'
import logo from 'src/shared/assets/logo.svg'

import styles from './Logo.module.scss'

interface LogoPropsType {
  className?: string,
}

export const Logo: FC<LogoPropsType> = ({ className }) => {
  const navigate = useNavigate()

  const handleLogoClick = useCallback(() => navigate(BASE_ROUTE), [])

  return <img className={cn(styles.Logo, className)} src={logo} onClick={handleLogoClick}/>
}
