import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { TextareaFormField } from 'src/shared/ui/TextareaFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { CheckboxFormField } from 'src/shared/ui/CheckboxFormField'
import { ProductSelectFormField } from '../ProductSelectFormField'
import { CollectionSelectFormField } from '../CollectionSelectFormField/CollectionSelectFormField'

import styles from './CabinetTasksNew.module.scss'

export const CabinetTasksNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_TASKS_ROUTE), [navigate])

  const methods = useForm()
  const { handleSubmit } = methods

  const onSubmit = useCallback(() => {}, [])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <div className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Создание задания</h1>
      </div>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.InputsContainers}>
            <div className={styles.InputsContainer}>
              <ProductSelectFormField
                id="product"
                name="product"
                label="Выберите товар"
                placeholder="Выбрать товар из списка"
              />
              <NumericInputFormField
                id="maxParticipants"
                name="maxParticipants"
                label="Укажите количество участников"
                placeholder="Количество участников"
                kind="cabinet"
              />
              <CollectionSelectFormField
                id="collection"
                name="collection"
                label="Выберите тип сбора"
                placeholder="Выберите тип сбора"
              />
              <div className={styles.Checkboxes}>
                <CheckboxFormField
                  id="preventDuplicateResponses"
                  name="preventDuplicateResponses"
                  label="Запретить повторный отклик"
                  withHiddenLabel
                >
                  Запретить повторный отклик
                </CheckboxFormField>
                <CheckboxFormField
                  id="preventReEntry"
                  name="preventReEntry"
                  label="Запретить повтоное участие"
                  withHiddenLabel
                >
                  Запретить повтоное участие
                </CheckboxFormField>
              </div>
            </div>
            <div className={styles.InputsContainer}>
              <TextareaFormField
                id="1"
                name="1"
                label="Ключевое слово для выкупа товара"
                placeholder="Укажите ключевое слово"
                kind="cabinet"
              />
              <TextareaFormField
                id="2"
                name="2"
                label="Требования к отзыву на маркетплейсе"
                placeholder="Опишите требования"
                kind="cabinet"
              />
              <TextareaFormField
                id="3"
                name="3"
                label="Требования к рекламной интеграции"
                placeholder="Опишите требования"
                kind="cabinet"
              />
            </div>
          </div>
          <CabinetButton className={styles.CabinetButton}>
            Создать заказ
          </CabinetButton>
        </form>
      </FormProvider>
    </CabinetPageLayout>
  )
}
