import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CABINET_ROUTE } from 'src/shared/config/consts'

import styles from './Logo.module.scss'

export const Logo: FC = () => {
  const navigate = useNavigate()
  const handleLogoClick = useCallback(() => navigate(CABINET_ROUTE), [])

  return (
    <button
      className={styles.Logo}
      onClick={handleLogoClick}
    >
      Marketiq
    </button>
  )
}
