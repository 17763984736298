import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './LandingPage.module.scss'
import { Header, HeaderPropsType } from '../Header/Header'
import { Footer } from '../Footer/Footer'

interface LandingPagePropsType extends HeaderPropsType {
  className?: string,
  children: ReactNode
}

export const LandingPage: FC<LandingPagePropsType> = ({ className, children, arrowButtonText, arrowButtonLink }) => {
  return (
    <div className={cn(styles.LandingPage, className)}>
      <Header
        arrowButtonText={arrowButtonText}
        arrowButtonLink={arrowButtonLink}
      />
      <div className={styles.Container}>
        {children}
      </div>
      <Footer/>
    </div>
  )
}
