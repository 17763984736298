import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from '../Blogger/Blogger'
import { BloggerButton } from '../BloggerButton/BloggerButton'

import styles from './PaymentSentBlogger.module.scss'

interface PaymentSentBloggerPropsType extends BloggerPropsType {
  onClick(): void
}

export const PaymentSentBlogger: FC<PaymentSentBloggerPropsType> = ({ onClick, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      <div className={styles.Container}>
        <span className={styles.Text}>
          Ожидаем подтверждения получения оплаты от блоггера
        </span>
        <BloggerButton onClick={onClick}>Детали интеграции</BloggerButton>
      </div>
    </Blogger>
  )
}
