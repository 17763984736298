import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from '../Blogger/Blogger'
import { BloggerButton } from '../BloggerButton/BloggerButton'

import styles from './RejectedBlogger.module.scss'

interface RejectedBloggerPropsType extends BloggerPropsType {
  onConfirm(): void
}

export const RejectedBlogger: FC<RejectedBloggerPropsType> = ({ onConfirm, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      <div className={styles.Container}>
        <span className={styles.Text}>
          Участник был отклонен вами ранее, однако вы можете принять его если передумаете.
        </span>
        <BloggerButton onClick={onConfirm}>Подтвердить участника</BloggerButton>
      </div>
    </Blogger>
  )
}
