import _get from 'lodash/get'
import { FieldPath, FieldValues, useController, UseControllerProps, UseControllerReturn, useFormContext } from 'react-hook-form'

type UseReactHookFormFieldControllerPropsType<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, TFieldName>, 'control'>

interface UseReactHookFormFieldControllerReturnType<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerReturn<TFieldValues, TFieldName> {
  error: string | undefined
}

export function useReactHookFormFieldController<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  ...props
}: UseReactHookFormFieldControllerPropsType<TFieldValues, TFieldName>): UseReactHookFormFieldControllerReturnType<
  TFieldValues,
  TFieldName
> {
  const { control, formState: { errors } } = useFormContext<TFieldValues>()
  const controller = useController({
    control,
    name,
    rules,
    ...props,
  })
  const error = _get(errors, name)?.message as unknown as string | undefined

  return {
    ...controller,
    error: controller.fieldState.error?.message ?? error,
  }
}
