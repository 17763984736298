import React, { FC } from 'react'

import styles from './Product.module.scss'

interface ProductPropsType {
  photoUrl: string
  title: string
  price: number
}

export const Product: FC<ProductPropsType> = ({ photoUrl, title, price }) => {
  return (
    <div className={styles.Product}>
      <img className={styles.Photo} src={photoUrl} />
      <div className={styles.Info}>
        <span className={styles.Title}>{title}</span>
        <span className={styles.Price}>{price} ₽</span>
      </div>
    </div>
  )
}
