import React, { FC } from 'react'

import image1 from 'src/shared/assets/blogger_main_1.png'
import image2 from 'src/shared/assets/blogger_main_2.png'
import image3 from 'src/shared/assets/blogger_main_3.png'
import image4 from 'src/shared/assets/blogger_main_4.png'
import image5 from 'src/shared/assets/blogger_main_5.png'
import image6 from 'src/shared/assets/blogger_main_6.png'

import styles from './MainImages.module.scss'

const IMAGES = [image1, image2, image3, image4, image5, image6]

export const MainImages: FC = () => {
  return (
    <div className={styles.MainImages}>
      {IMAGES.map((image, index) => (
        <div className={styles.ImageWrapper} key={index}>
          <img className={styles.Image} src={image} />
        </div>
      ))}
    </div>
  )
}
