import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'

import styles from './BloggerButton.module.scss'

interface BloggerButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: 'primary' | 'ghost' | 'danger'
}

export const BloggerButton: FC<BloggerButtonPropsType> = ({ className, kind = 'primary', ...props }) => {
  return (
    <button
      className={cn(
        styles.BloggerButton,
        kind === 'ghost' && styles.BloggerButton__kindGhost,
        kind === 'danger' && styles.BloggerButton__kindDanger,
        className
      )}
      {...props}
   />
  )
}
