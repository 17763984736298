import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './AuthButton.module.scss'

interface AuthButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
}

export const AuthButton: FC<AuthButtonPropsType> = ({ children, className, ...props }) => {
  return (
    <button className={cn(styles.AuthButton, className)} {...props}>
      {children}
    </button>
  )
}
