import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'
import { Icon } from 'src/shared/ui/Icon'

import styles from './СollectionButton.module.scss'

interface СollectionButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind: 'start' | 'stop'
}

export const СollectionButton: FC<СollectionButtonPropsType> = ({ className, kind, ...props }) => {
  return (
    <button className={cn(
        styles.СollectionButton,
        kind === 'start' ? styles.СollectionButton__kindStart : styles.СollectionButton__kindStop,
        className
      )}
      {...props}
    >
      {kind === 'start' ? 'Возобновить сбор' : 'Приоставить сбор'}
      {<Icon name={kind === 'start' ? 'start' : 'stop'} />}
    </button>
  )
}
