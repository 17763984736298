import React, { FC } from 'react'
import cn from 'classnames'
import { NumericInput, NumericInputPropsType } from '../NumericInput'

import styles from './PhoneInput.module.scss'

export type PhoneInputPropsType = NumericInputPropsType

export const PhoneInput: FC<PhoneInputPropsType> = ({ className, ...rest }) => {
  return (
    <div className={cn(styles.PhoneInput, className)}>
      <div className={styles.Code}>+7</div>
      <NumericInput className={styles.NumericInput} {...rest}/>
    </div>
  )
}
