import { ControllerRenderProps, FieldValues, UseControllerProps } from "react-hook-form"

export interface PageConfigType {
  title: string
  route: string
}

export type PrepareFieldPropsType<TFieldProps> = Omit<
  TFieldProps,
  'error' | 'defaultValue' | keyof Omit<ControllerRenderProps, 'onBlur'>
>

export type ReactHookFormFieldType<TFieldValues extends FieldValues, TFieldProps extends Record<string, any>> = Omit<
  UseControllerProps<TFieldValues>,
  'control'
> &
  PrepareFieldPropsType<TFieldProps>

export enum CollectionType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}
